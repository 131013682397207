<template>
  <CardToolbar
    @days-changed="changeDayValue"
    description="Artist fanbase growth"
    title="Fanbase Growth"
    :show-toolbar-buttons="true"
  >
    <template v-slot:toolbar>
      <!--      <button-->
      <!--        v-if="showDeSelectButton"-->
      <!--        class="btn btn-sm btn-primary me-3"-->
      <!--        @click="deselectAll"-->
      <!--      >-->
      <!--        Deselect All-->
      <!--      </button>-->
      <!--      <span v-else />-->
    </template>
    <template v-slot:body>
      <el-skeleton :loading="loading" animated :rows="5">
        <template #default>
          <RevenueChart
            :series="fanbaseChartSeries"
            :categories="fanbaseChartCategories"
            :colors="fanbaseChartColors"
            :currency-values="false"
            @deselected="seriesDeselected"
            :deselect-series="deselectSeries"
          />
        </template>
      </el-skeleton>
    </template>
  </CardToolbar>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

import CardToolbar from "../../common/components/CardToolbar";
import { getFanbaseGrowth, getFanbaseOvertime } from "@/api/artists.api";
import { platforms } from "@/assets/static/platforms";
import RevenueChart from "../../common/components/RevenueChart";

export default {
  name: "FanbaseOvertime",
  components: { CardToolbar, RevenueChart },
  props: {
    platforms: {
      type: Array,
      default: () => [],
    },
    uuid: {
      type: String,
      required: true,
    },
    showDeSelectButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const route = useRoute();
    const deselectSeries = ref(false);
    const fanbaseChartSeries = ref([]);
    const fanbaseChartCategories = ref([]);
    const fanbaseChartColors = ref([]);
    const loading = ref(true);
    const days = ref(365);
    const isYearData = ref(true);

    // onMounted(async () => {
    //   await getFanbaseData(props.uuid);
    // });

    const deselectAll = () => {
      deselectSeries.value = true;
    };

    const resetData = () => {
      fanbaseChartSeries.value = [];
      fanbaseChartCategories.value = [];
    };

    const getFanbaseData = async (uuid) => {
      try {
        loading.value = true;
        resetData();
        let promises = [];
        Object.values(platforms).forEach((platform) => {
          if (
            props.platforms.length === 0 ||
            props.platforms.includes(platform.name)
          ) {
            promises.push(
              // getFanbaseOvertime(uuid, platform.name).then((resp) => {
              getFanbaseGrowth(
                uuid,
                platform.name,
                days.value,
                isYearData.value
              ).then((resp) => {
                const object = {
                  data: resp.data.yAxis,
                  name: platform.name,
                };
                fanbaseChartSeries.value.push(object);
                fanbaseChartColors.value.push(platform.color);
                if (!fanbaseChartCategories.value.length) {
                  fanbaseChartCategories.value = [...resp.data.xAxis];
                }
              })
            );
          }
        });
        await Promise.all(promises);
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };
    const changeDayValue = async (day) => {
      days.value = day;
      if (days.value === 365) {
        isYearData.value = true;
      } else {
        isYearData.value = false;
      }
      await getFanbaseData(props.uuid);
    };
    const seriesDeselected = () => {
      deselectSeries.value = false;
    };

    watch(
      () => props.uuid,
      (value) => {
        if (value) {
          getFanbaseData(value);
        }
      },
      { immediate: true }
    );

    return {
      deselectAll,
      deselectSeries,
      fanbaseChartSeries,
      fanbaseChartCategories,
      fanbaseChartColors,
      days,
      isYearData,
      loading,
      seriesDeselected,
      changeDayValue,
    };
  },
};
</script>

<style scoped></style>
