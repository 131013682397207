<template>
  <el-skeleton :rows="8" animated v-if="loading" />
  <div class="fanbase-line-chart" v-else>
    <div class="card-title d-flex align-items-center">
      <el-image
        class="icon"
        :src="'/media/svg/social-logos/' + platform + '.svg'"
      />
      <div class="card-label fw-bolder m-lg-4">
        {{ platform.toUpperCase() }}
      </div>
    </div>
    <apexchart
      type="line"
      v-loading="loading"
      height="350"
      :series="data"
      :options="chartOptions"
    ></apexchart>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { getFanbaseGrowth } from "@/api/artists.api";
export default {
  name: "FanbaseLineChart",
  props: {
    platform: {
      type: String,
      required: true,
    },
    days: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = ref({});
    const data = ref([]);
    const loading = ref(false);
    const route = useRoute();

    onMounted(async () => {
      await fetchGraphData(route.params.uuid, props.platform, props.days, true);
    });

    async function fetchGraphData(uuid, platform, days, isYearData = true) {
      try {
        loading.value = true;
        const response = await getFanbaseGrowth(
          uuid,
          platform,
          days,
          isYearData
        );
        data.value = [
          {
            name: "Followers",
            data: response.data.yAxis,
          },
        ];
        chartOptions.value = {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: [props.color],
          stroke: {
            curve: "smooth",
          },
          grid: {
            show: false,
          },
          xaxis: {
            categories: response.data.xAxis,
            labels: {
              rotate: -90,
              rotateAlways: true,
            },
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return Number(value).toLocaleString();
              },
            },
          },
        };
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    watch(
      () => props.days,
      async (currentValue) => {
        if (currentValue === 365) {
          await fetchGraphData(
            route.params.uuid,
            props.platform,
            currentValue,
            true
          );
        } else {
          await fetchGraphData(
            route.params.uuid,
            props.platform,
            currentValue,
            false
          );
        }
      }
    );

    return { data, chartOptions, loading };
  },
};
</script>

<style lang="scss" scoped>
.fanbase-line-chart {
  .icon {
    height: 40px;
    width: 40px;
  }
}
</style>
