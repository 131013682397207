<template>
  <FanbaseOvertime :uuid="$route.params.uuid" />
  <FanbaseDistribution />
  <FanbaseGrowth />
</template>

<script>
import FanbaseDistribution from "../components/FanbaseDistribution";
import FanbaseGrowth from "../components/FanbaseGrowth";
import FanbaseOvertime from "../components/FanbaseOvertime";

export default {
  components: { FanbaseDistribution, FanbaseGrowth, FanbaseOvertime },
  name: "Fanbase",
};
</script>

<style scoped></style>
