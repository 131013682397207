<template>
  <CardToolbar
    @days-changed="changeDayValue"
    description="The size of Artist's fanbase and distribution across social media and
          music channels"
    :show-toolbar-buttons="true"
    title="Fanbase Growth"
  >
    <template v-slot:body>
      <el-row :gutter="16">
        <el-col v-for="platform in platforms" :key="platform" :xs="24" :lg="8">
          <div class="mb-5 mb-xl-10 border p-2">
            <FanbaseLineChart
              :platform="platform.name"
              :color="platform.color"
              :days="days"
            />
          </div>
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>
<script>
import { ref } from "vue";

import FanbaseLineChart from "./FanbaseLineChart.vue";
import CardToolbar from "../../common/components/CardToolbar";
import { platforms } from "@/assets/static/platforms";

export default {
  components: { FanbaseLineChart, CardToolbar },
  setup() {
    const loading = ref(false);
    const days = ref(365);

    const changeDayValue = (day) => {
      days.value = day;
    };

    return {
      loading,
      days,
      platforms,
      changeDayValue,
    };
  },
};
</script>
<style lang="scss" scoped></style>
